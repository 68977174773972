import React from "react";

const Map = React.memo(() => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.004592082639!2d27.994737975411628!3d-26.098768777141597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9574956d423d1d%3A0x88145cb827a806c!2s260%20Surrey%20Ave%2C%20Ferndale%2C%20Randburg%2C%202194!5e0!3m2!1sen!2sza!4v1732370176768!5m2!1sen!2sza"
      width="100%"  // Set width to 100% for responsiveness
      height="450"  // Set fixed height
      className="mx-auto border border-gray-400 shadow-md mb-8"
      title="Google Maps Location"
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
});

export default Map;
