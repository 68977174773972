import React from "react";
import { Link } from "react-router-dom";
import { IoLocationSharp, IoCall, IoMail } from "react-icons/io5";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white font-light">
      <div className="container mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo */}
          <div className="flex flex-col items-center md:items-start">
            <img
              src="/images/logo.png"
              alt="Logo"
              className="h-20 mb-4 rounded"
            />
          </div>

          {/* Quick Links */}
          <nav className="flex flex-col">
            <h2 className="text-lg font-semibold mb-4">Quick Links</h2>
            <ul className="text-sm space-y-2">
              <li>
                <Link
                  to="/"
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about/company"
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/services/training"
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>

          {/* Social Links */}
          <div className="flex flex-col">
            <h2 className="text-lg font-semibold mb-4">Follow Us</h2>
            <div className="flex space-x-4">
              {/* Facebook */}
              <a
                href="https://www.facebook.com/ikusasaTech"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-full bg-white text-gray-800 hover:text-white hover:bg-gray-700 transition-colors duration-300"
                aria-label="Facebook"
              >
                <img src="/icons/fb.svg" alt="Facebook" className="h-6 w-6" />
              </a>
              
              {/* LinkedIn */}
              <a
                href="https://www.linkedin.com/company/ikusasatech"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-full bg-white text-gray-800 hover:text-white hover:bg-gray-700 transition-colors duration-300"
                aria-label="LinkedIn"
              >
                <img src="/icons/ln.svg" alt="LinkedIn" className="h-6 w-6" />
              </a>
              
              {/* Instagram */}
              <a
                href="https://www.instagram.com/ikusasatechsolutions"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-full bg-white text-gray-800 hover:text-white hover:bg-gray-700 transition-colors duration-300"
                aria-label="Instagram"
              >
                <img
                  src="/icons/inst.svg"
                  alt="Instagram"
                  className="h-6 w-6"
                />
              </a>
              
              {/* Twitter */}
              <a
                href="https://twitter.com/ikusasa_ict?lang=en"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-full bg-white text-gray-800 hover:text-white hover:bg-gray-700 transition-colors duration-300"
                aria-label="Twitter"
              >
                <img src="/icons/x.svg" alt="Twitter" className="h-5 w-6" />
              </a>
              
              {/* WhatsApp */}
              <a
                href="https://wa.me/+27108241154"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-full bg-white text-gray-800 hover:text-white hover:bg-gray-700 transition-colors duration-300"
                aria-label="WhatsApp"
              >
                <img src="/icons/w.svg" alt="WhatsApp" className="h-6 w-6" />
              </a>
            </div>
          </div>

          {/* Contact Us */}
          <div className="flex flex-col">
            <h2 className="text-lg font-semibold mb-4">Contact Us</h2>
            <div className="flex items-center mb-4">
              <IoLocationSharp className="w-5 h-5 mr-2 text-red-500" />
              <p className="text-sm">Unit A5, 260 Surrey Ave, Ferndale, Randburg, 2194</p>
            </div>
            <div className="flex items-center mb-4">
              <IoCall className="w-5 h-5 mr-2 text-gray-400" />
              <p className="text-sm">010 824 1153/54</p>
            </div>
            <div className="flex items-center">
              <IoMail className="w-5 h-5 mr-2 text-gray-400" />
              <p className="text-sm">info@ikusasatech.com</p>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="bg-black py-4 text-sm text-center text-gray-500">
        <p>
          &copy; {new Date().getFullYear()} Ikusasa Technology Solutions. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
