import React, { useState, useCallback } from "react";

// Modal Component
const Modal = ({ imageUrl, caption, onClose }) => (
  <div
    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
    onClick={onClose}
  >
    <div
      className="relative max-w-3xl w-full bg-white rounded-lg shadow-xl overflow-hidden"
      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
    >
      <button
        className="absolute top-4 right-4 text-white bg-black bg-opacity-60 border border-white px-4 py-2 rounded-full shadow-md transition-opacity duration-300 hover:opacity-80"
        onClick={onClose}
      >
        Close
      </button>
      <img
        src={imageUrl}
        alt="Selected"
        className="w-full h-auto max-h-screen object-cover"
      />
      <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-60 text-white text-center py-2">
        {caption}
      </div>
    </div>
  </div>
);

// ImageItem Component
const ImageItem = ({ imageUrl, caption, onViewClick }) => (
  <div className="relative group rounded overflow-hidden shadow-lg transition-transform transform hover:scale-105">
    <img
      src={imageUrl}
      alt="Gallery Item"
      className="w-full h-full object-cover"
      onClick={() => onViewClick(imageUrl, caption)}
    />
    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-60 rounded-lg">
      <button
        className="text-white bg-transparent border border-white px-6 py-3 rounded-full shadow-md transition-transform duration-300 transform hover:scale-110"
        onClick={() => onViewClick(imageUrl, caption)}
      >
        View
      </button>
    </div>
  </div>
);

// Gallery Component
const Gallery = () => {
  const images = [
 
    { url: "./images/gal25.jpeg", caption: "Team" },
    { url: "./images/gal26.jpeg", caption: "Team" },
    { url: "./images/gal23.jpg", caption: "KZN IoT training Program" },
    { url: "./images/gal22.jpg", caption: "KZN IoT training Program" },
    { url: "./images/gal21.jpg", caption: "KZN IoT training Program" },
    { url: "./images/gal20.jpg", caption: "KZN IoT training Program" },
    { url: "./images/gal9.jpeg", caption: "System Support Learners" },
    { url: "./images/gal7.jpeg", caption: "System Support Learners" },
    { url: "./images/gal8.jpeg", caption: "System Support Learners" },
    { url: "./images/gal1.jpeg", caption: "2023 OMI System Development Cohort" },
    { url: "./images/gal2.jpeg", caption: "2023 OMI System Development Cohort" },
    { url: "./images/gal3.jpeg", caption: "" },
    { url: "./images/gal4.jpg", caption: "" },
    { url: "./images/gal5.jpg", caption: "" },
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleViewClick = useCallback((imageUrl, caption) => {
    setSelectedImage({ imageUrl, caption });
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedImage(null);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <ImageItem
            key={index}
            imageUrl={image.url}
            caption={image.caption}
            onViewClick={handleViewClick}
          />
        ))}
      </div>
      {selectedImage && (
        <Modal
          imageUrl={selectedImage.imageUrl}
          caption={selectedImage.caption}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Gallery;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios'; 

// const ImageSlideshow = () => {
//   // State variables
//   const [images, setImages] = useState([]); // To store image URLs
//   const [currentSlide, setCurrentSlide] = useState(0); // To track the current slide
//   const [isPaused, setIsPaused] = useState(false); // To pause the slideshow

//   // Fetch images from the API when the component mounts
//   useEffect(() => {
//     const fetchImages = async () => {
//       try {
//         const response = await axios.get('https://cms-backend-w1p9.onrender.com/api/content/6710f78b64b9de5448554a55', {
//           headers: {
//             'Authorization': 'Bearer e9b6c0207ecdd928383296944b2d8ce216a29d29b8c2f5b312de2520ecb8a198'
//           }
//         });
//         console.log('API Response:', response.data); // Log the entire response

//         // Check if the response contains the expected gallery array
//         if (response.data.gallery && Array.isArray(response.data.gallery)) {
//           // Extract image URLs from the gallery array
//           const urls = response.data.gallery.map(item => item.image); // Use item.image to get the URL
//           console.log('Image URLs:', urls); // Log the image URLs
//           setImages(urls); // Update state with the URLs
//         } else {
//           console.error('Expected an array of pictures but got:', response.data.gallery);
//         }
//       } catch (error) {
//         console.error('Error fetching images:', error); // Log any errors
//       }
//     };

//     fetchImages(); 
//   }, []); // Empty dependency array means this runs once on mount

//   // Handle slideshow interval
//   useEffect(() => {
//     if (isPaused || images.length === 0) return; // Don't run if paused or no images

//     const intervalId = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length); // Cycle through images
//     }, 4000); // Change slide every 4 seconds

//     return () => clearInterval(intervalId); // Cleanup on unmount or dependency change
//   }, [isPaused, images.length]); // Dependencies to watch for changes

//   // Navigate to the previous slide
//   const handlePrevSlide = () => {
//     setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
//   };

//   // Navigate to the next slide
//   const handleNextSlide = () => {
//     setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
//   };

//   return (
//     <div
//       className="slideshow-container min-h-screen"
//       onMouseEnter={() => setIsPaused(true)} // Pause on hover
//       onMouseLeave={() => setIsPaused(false)} // Resume on mouse leave
//     >
//       {images.length > 0 ? (
//         <img 
//           src={images[currentSlide]} 
//           alt={`Slide ${currentSlide + 1}`} 
//           className="slideshow-image" 
//         />
//       ) : (
//         <p>Loading images...</p> // Show loading message
//       )}
//       <button className="prev-button" onClick={handlePrevSlide}>
//         &lt; {/* Left arrow */}
//       </button>
//       <button className="next-button" onClick={handleNextSlide}>
//         &gt; {/* Right arrow */}
//       </button>
//     </div>
//   );
// };

// export default ImageSlideshow;



