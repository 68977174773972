import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiClock, FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { RiUserFill } from 'react-icons/ri';
import blogPosts from '../data/blogPosts';
import SearchBar from '../components/SearchBar';

const Blog = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('');
  const postsPerPage = 5;

  const filteredPosts = useMemo(() => {
    return blogPosts
      .filter(post =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (!selectedCategory || post.categories.includes(selectedCategory))
      )
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [searchQuery, selectedCategory]);

  const currentPosts = useMemo(() => {
    const start = (currentPage - 1) * postsPerPage;
    return filteredPosts.slice(start, start + postsPerPage);
  }, [filteredPosts, currentPage]);

  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  const truncateContent = (content) => {
    const words = content.split(' ');
    return words.length > 50 ? words.slice(0, 50).join(' ') + '...' : content;
  };

  const categories = useMemo(() => Array.from(new Set(blogPosts.flatMap(post => post.categories))), []);

  return (
    <div className="min-h-screen bg-blue-50 py-8 px-4 sm:px-6 lg:px-8" style={{ backgroundImage: `url('/images/blog2.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold text-customBlue mb-8">Technology Made Simple: Insights and Solutions</h1>

        <SearchBar handleSearch={handleSearch} />

        <div className="mb-4">
          <label htmlFor="category" className="block text-sm font-medium text-gray-700">Filter by Category:</label>
          <select
            id="category"
            name="category"
            className="block w-full py-2 pl-3 pr-10 border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={selectedCategory}
            onChange={(e) => handleCategoryFilter(e.target.value)}
          >
            <option value="">All Categories</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>

        {filteredPosts.length === 0 ? (
          <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-8 text-gray-800">
            <p>No blog posts found.</p>
          </div>
        ) : (
          currentPosts.map(post => (
            <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden mb-8 transition duration-300 ease-in-out transform hover:scale-105">
              <img src={post.featuredImage} alt={post.title} className="w-full h-64 object-cover rounded-t-lg" />

              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                  <Link to={`/blog/${post.slug}`} className="hover:underline text-blue-600">{post.title}</Link>
                </h2>

                <div className='flex flex-auto font-thin text-sm '>
                  <div className="flex items-center text-gray-600 mb-2 mr-3">
                    <FiClock className="mr-1" />
                    <span>{post.date}</span>
                  </div>
                  <div className="flex items-center text-gray-600 mb-2">
                    <RiUserFill className="mr-1" />
                    <span>{post.author}</span>
                  </div>
                </div>

                <p className="text-gray-800 mb-4">{truncateContent(post.content)}</p>
                <Link to={`/blog/${post.slug}`} className="text-blue-600 hover:underline">
                  Continue Reading
                </Link>
              </div>
            </div>
          ))
        )}

        <div className="flex justify-center mt-8">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
              className={`bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-600 px-3 py-2 rounded-l-md focus:outline-none ${currentPage === 1 ? 'cursor-not-allowed' : ''}`}
            >
              <FiChevronsLeft />
            </button>
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={`bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-600 px-3 py-2 focus:outline-none ${currentPage === 1 ? 'cursor-not-allowed' : ''}`}
            >
              <FiChevronLeft />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-600 px-3 py-2 focus:outline-none ${currentPage === index + 1 ? 'font-semibold bg-gray-200 border-b-2' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-600 px-3 py-2 focus:outline-none ${currentPage === totalPages ? 'cursor-not-allowed' : ''}`}
            >
              <FiChevronRight />
            </button>
            <button
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
              className={`bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-600 px-3 py-2 rounded-r-md focus:outline-none ${currentPage === totalPages ? 'cursor-not-allowed' : ''}`}
            >
              <FiChevronsRight />
            </button>
          </nav>
        </div>
      </div>

    </div>
  );
};

export default Blog;
