import React, { useState, useEffect, useRef } from 'react';

const SuccessStory = () => {
  // Sample IoT-specific data
  const successStories = [
    {
      title: 'Michalene Smith',
      content:
        'Sarah transitioned from a finance background to becoming a data engineer. With our IoT courses, Sarah is now working with big data platforms at a major tech firm.',
      image: '/images/sarah-lee.jpg',
      videoUrl: '/videos/smith.mp4',
      program: 'IoT',
    },
    {
      title: 'Kim Jody Olliver',
      content:
        'The 3-month program was very helpful for me. I’d recommend Ikusasa for this course if you want to pass your IoT exam',
      image: '/images/jake-moore.jpg',
      videoUrl: '/videos/kim.mp4',
      program: 'IoT',
    },
    {
      title: 'Keegan Kriel',
      content:
        'Nina applied her IoT knowledge to develop healthcare solutions that improve patient care. She now works in a top tech firm.',
      image: '/images/keegan.jpg',
      videoUrl: '/videos/keegan.mp4',
      program: 'IoT',
    }
  ];

  // Achievements list
  const achievements = [
    'Global Awards 2022/23 ICT Training Service of the Year.',
    'Digital Public Service Award 2022 (SITA GovTech).',
    'HERA Award International Women.',
    'WIA 2021 Laurette Education Relevance.',
    'Auda-Nepad Digitization of Contents for 100,000 SMMEs.',
    'South African Small Business Award 2020 (Top 20 Award).',
    'Finalist Nedbank Business Ignite 2020.',
    'Roodepoort Chamber of Commerce & Industry (ROCCI) Awarded Business of the Year 2018/2019 in the Small, Medium Category.',
    'Under the MICT SETA Accreditation, we’ve trained ±1300 learners from underprivileged communities on various qualifications: System Support, Technical Support, Penetration Cyber Security (Work Skill Program), and Systems Development.',
    'We had the opportunity to deliver CompTIA CTT training for WHO (World Health Organization) in Uganda in June 2018 / Nov 2019.',
  ];

  // State to track the filtered stories (only IoT)
  const [filteredStories, setFilteredStories] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null); // Track which video is currently playing
  const videoRefs = useRef([]); // Create a reference array for video elements

  // Filter the success stories based on the selected program (only IoT)
  useEffect(() => {
    const stories = successStories.filter((story) => story.program === 'IoT');
    setFilteredStories(stories);
  }, []);

  // Handle video play logic
  const handleVideoPlay = (index) => {
    // Pause all videos before playing the selected one
    videoRefs.current.forEach((videoRef, i) => {
      if (i !== index) {
        videoRef.pause(); // Pause other videos
      }
    });

    const video = videoRefs.current[index];

    // If the same video is clicked again, stop it
    if (currentlyPlaying === index) {
      video.pause();
      setCurrentlyPlaying(null);
    } else {
      video.play();
      setCurrentlyPlaying(index); // Set the index of the currently playing video
    }
  };

  return (
    <div className="container mx-auto px-6 py-8">
      <h1 className="text-4xl font-semibold text-center mb-10 text-gray-800">IoT Success Stories</h1>

      {/* Display filtered IoT success stories */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredStories.length > 0 ? (
          filteredStories.map((story, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:scale-105"
            >
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">{story.title}</h2>

              {/* MP4 Video */}
              <div className="aspect-w-16 aspect-h-9 mb-6">
                <video
                  ref={(el) => (videoRefs.current[index] = el)} // Assign video element to ref array
                  width="100%"
                  height="auto"
                  controls
                  className="rounded-md"
                  onPlay={() => handleVideoPlay(index)} // Handle play event
                >
                  <source src={story.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          ))
        ) : (
          <p>No success stories available for IoT program yet.</p>
        )}
      </div>

      {/* Achievements Section */}
      <section className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-12 mt-16">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-semibold mb-8">Our Achievements</h2>
          <div className="bg-white text-gray-800 p-8 rounded-lg shadow-lg">
            <ul className="list-disc list-inside text-lg space-y-4">
              {achievements.map((achievement, index) => (
                <li key={index}>{achievement}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SuccessStory;
