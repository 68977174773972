import React, { useState, useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import Confetti from 'react-confetti';  // Import Confetti for celebration
import { FaTrophy } from 'react-icons/fa';

const slideImages = [
  {
    url: 'images/t2.jpeg',
    caption: 'Training',
    description: 'Stay Relevant with Market-Demanded Skills',
    link: '/training',
    additionalInfo: '4000+ trained youth, 85% placement rate',
  },
  {
    url: 'images/c6.jpeg',
    caption: 'Consulting',
    description: 'Expert Consulting for Strategic Growth',
    link: '/consulting',
  },
  {
    url: 'images/reseller3.jpeg',
    caption: 'Reseller',
    description: 'Comprehensive IT Solutions and Trusted Reseller Services',
    link: '/reseller',
  },
  // Updated IoT congratulatory slide
  {
    url: 'images/gal21.jpg',  // Replace with actual image for IoT achievement
    caption: 'IoT Training Program Achievement',
    description: 'We Congratulate IoT learners on Completing the IoT Program with a 100% Pass Rate!',
    link: '/success',
    isCelebratory: true,  // Flag for celebratory styling
  },
];

const HeroSection = () => {
  const [trainedCount, setTrainedCount] = useState(0);
  const [placementRate, setPlacementRate] = useState(0);
  const [startTouchY, setStartTouchY] = useState(null);
  const [startTouchX, setStartTouchX] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (slideImages[0].caption === 'Training') {
      // Animation for trained count
      const trainedInterval = setInterval(() => {
        if (trainedCount < 4000) {
          setTrainedCount((prevCount) => prevCount + 250);
        } else {
          clearInterval(trainedInterval);
        }
      }, 80);

      // Animation for placement rate (assuming percentage)
      const placementInterval = setInterval(() => {
        if (placementRate < 85) {
          setPlacementRate((prevRate) => prevRate + 5);
        } else {
          clearInterval(placementInterval);
        }
      }, 80);

      return () => {
        clearInterval(trainedInterval);
        clearInterval(placementInterval);
      };
    }
  }, [trainedCount, placementRate]);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartTouchY(touch.clientY);
    setStartTouchX(touch.clientX);
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const endTouchY = touch.clientY;
    const endTouchX = touch.clientX;

    const verticalSwipeThreshold = 50; // Minimum distance to detect vertical swipe
    const horizontalSwipeThreshold = 50;

    const diffY = endTouchY - startTouchY;
    const diffX = endTouchX - startTouchX;

    if (Math.abs(diffY) > verticalSwipeThreshold && Math.abs(diffX) < horizontalSwipeThreshold) {
      // Vertical swipe detected
      if (diffY < 0) {
        // Swipe up
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slideImages.length);
      } else {
        // Swipe down
        setCurrentIndex((prevIndex) => (prevIndex - 1 + slideImages.length) % slideImages.length);
      }
    }
  };

  return (
    <div className="relative bg-gray-50" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <Slide
        arrows={true}
        indicators={true}
        autoplay={true}
        pauseOnHover={false}
        duration={5000}
        transitionDuration={0}
        defaultIndex={0}
        prevArrow={
          <IoIosArrowBack className="absolute left-0 arrow prev text-white text-4xl bg-black bg-opacity-50 p-2 rounded-full hover:bg-gray-800 transition duration-300" />
        }
        nextArrow={
          <IoIosArrowForward className="absolute right-0 arrow next text-white text-4xl bg-black bg-opacity-50 p-2 rounded-full hover:bg-gray-800 transition duration-300" />
        }
        className="slide-container"
        index={currentIndex}
      >
        {slideImages.map((slide, index) => (
          <div
            key={index}
            className="relative h-screen flex items-center justify-center"
            style={{
              backgroundImage: `url(${slide.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-55 flex items-center justify-center text-white">
              <div className="text-center">
                <h2 className="text-4xl font-bold">{slide.caption}</h2>
                <p className="text-lg">{slide.description}</p>
                {slide.additionalInfo && slide.caption === 'Training' && (
                  <div>
                    <p className="text-md mt-2 flex items-center justify-center">
                      <span className="font-extrabold text-3xl mr-2">{`${trainedCount}+`}</span> Trained Youth
                    </p>
                    <p className="text-md flex items-center justify-center">
                      <span className="font-extrabold text-3xl mr-2">{`${placementRate}%`}</span> Placement Rate
                    </p>
                  </div>
                )}

                {/* IoT Slide - Only for IoT */}
                {slide.isCelebratory && (
                  <div className="mt-4 text-3xl font-bold text-yellow-400">
                    <p className="text-center text-5xl font-extrabold text-white">🎉 Congratulations! 🎉</p>
                    <p className="text-center text-4xl font-extrabold">100% Pass Rate - You Did It!</p>
                    <div className="mt-4">
                      <Link to={slide.link}>
                        <button className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white px-6 py-3 rounded-full shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
                          View Success Stories
                        </button>
                      </Link>
                    </div>
                    <div className="celebration-icons mt-4">
                      {/* Trophy icon from react-icons */}
                      <FaTrophy className="w-16 h-16 mx-auto text-yellow-500" />
                    </div>
                  </div>
                )}

                {/* Standard "Read More" Button for other slides */}
                {!slide.isCelebratory && (
                  <div className="mt-4">
                    <Link to={`services${slide.link}`}>
                      <button className="bg-buttonBg1 hover:bg-buttonBg2 text-white px-4 py-2 rounded-full border-none cursor-pointer transition duration-300 transform hover:scale-105">
                        Read More
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>

            {/* Confetti animation for IoT slide */}
            {slide.isCelebratory && <Confetti width={window.innerWidth} height={window.innerHeight} />}
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default HeroSection;
