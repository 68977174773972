import React from "react";
import Map from "../components/Map";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <div className="top-56">
      <ContactForm/>
      <Map />
    </div>
  );
};

export default Contact;
